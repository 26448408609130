

.container {
	max-width: 1316px !important;
}

.section-contact {
	.contact-phone {
        @apply text-red uppercase;

        svg {
            @apply text-white relative z-10;
        }

        a {
            @apply relative;
            &:before {
                content: "";
                @apply absolute bg-red w-8 h-8 rounded-full bg-red top-0 left-0 z-0 -mt-1 -ml-2 transition-all duration-300;

                @screen lg { 
                	@apply -mt-0.5;
                }
            }

            &:hover {
                @apply text-offBlack;

                &:before { 
                    @apply bg-offBlack;
                }
            }
        }
    }

    .location-list {
    	li {
    		@apply font-proxReg text-offBlack;

    		@screen lg {
    			@apply text-xl;
    		}

	    	a {
	    		@apply text-red underline;

	    		&:hover {
	    			@apply no-underline;
	    		}
	    	}
    	}
    }

    .team-list {
    	li {
    		@apply font-proxReg text-offBlack;

    		@screen lg {
    			@apply text-xl;
    		}
    		
	    	a {
	    		&:hover {
	    			@apply text-red;
	    		}
	    	}
    	}
    }
}

.section-cta {
    .button.primary {
        &:hover {
            color: #ffffff !important
        }
    }
}