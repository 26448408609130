.section-img-text {
	
	&__wrapper {

		@screen lg {
			@apply flex items-start justify-between;

			.copy-wrap {
				@apply pl-8;
			}
		}

		@screen xl {
			.copy-wrap {
				@apply pl-14;
			}
		}

	}

	&.img-right {
		.section-img-text__wrapper {
			@screen lg {
				@apply flex-row-reverse;

				.copy-wrap {
					@apply pl-0 pr-8;
				}
			}

			@screen xl {
				.copy-wrap {
					@apply pr-14;
				}
			}
		}
	}
}