.section-header {

    .logo {
        @apply w-40;

        @screen lg { 
             @apply w-48;
        }
    }

    .logo-white {
        @apply hidden;
    }

    .logo-colour {
        @apply block;
    }

    a.contact-phone-mob {
        @apply w-10 h-10 rounded-full bg-red flex items-center justify-center mr-6;

        svg {
            @apply text-white relative z-10;
        }
       
        &:hover {
            @apply text-offBlack;

            &:before { 
                @apply bg-offBlack;
            }
        }
    }
    
    .desktop-nav { 

        @apply hidden;

        @screen lg {
            @apply block;
        }

        .contact-phone {
            @apply text-red uppercase;

            svg {
                @apply text-white relative z-10;
            }

            a {
                @apply relative;
                &:before {
                    content: "";
                    @apply absolute bg-red w-8 h-8 rounded-full bg-red top-0 left-0 z-0 -mt-0.5 -ml-2 transition-all duration-300;
                }

                &:hover {
                    @apply text-offBlack;

                    &:before { 
                        @apply bg-offBlack;
                    }
                }
            }
        }

        .primary-list { 
            @apply flex items-center justify-end;

            > li {
                @apply pl-6 font-proxSemi;
                font-size: 18px;

                @screen xl {
                    font-size: 22px;
                    @apply pl-7;
                }


                > a { 
                    @apply text-offBlack relative pb-6 block;

                    &:before {
                        content: "";
                        @apply absolute left-0 w-full h-0.5 bottom-0 mb-6 bg-offBlack transition-all duration-300 opacity-0;
                    }

                    &:hover,
                    &:active, 
                    &:focus,
                    &.active {
                        &:before {
                            @apply opacity-100; 
                        }   
                    }
                }

                .sub-nav {
                    @apply w-full pt-14 pb-20 absolute invisible left-0 bg-offBlack opacity-0;
                    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                    z-index: -1;

                    &__ul {

                        @apply flex items-start flex-wrap justify-start px-16;

                        li {
                            font-size: 22px;
                            @apply px-5 w-1/3;

                            a {
                                @apply text-white relative py-6 block;

                                &:after {
                                    content: "";
                                    @apply absolute left-0 w-full h-1.5 bottom-0 bg-dkGrey transition-all duration-300 -mb-1;
                                }

                                &:hover {
                                    &:after {
                                        @apply bg-white;
                                    }
                                }
                            }
                        }

                    }
                }

                &.parent-sub-nav-sm {
                    @apply relative;

                    .sub-nav-sm {
                        @apply absolute w-80 p-10 bg-offBlack top-auto left-0 ml-6 invisible opacity-0;
                        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);

                        li {
                            font-size: 22px;
                            a {
                                @apply text-white relative py-6 block;

                                &:after {
                                    content: "";
                                    @apply absolute left-0 w-full h-1 bottom-0 bg-dkGrey transition-all duration-300 -mb-1;
                                }

                                &:hover {
                                    &:after {
                                        @apply bg-white;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(4) {
                        .sub-nav-sm { 
                            @apply left-auto right-0;
                        }
                    }
                }

                &:hover {
                    .sub-nav-sm, 
                    .sub-nav {
                        @apply visible opacity-100;
                        z-index: 100;
                    }

                    > a {
                        &:before {
                            opacity: 1 !important;
                        }
                    }
                }
            }
        }
    }
}

.home {
    .section-header {
        margin-bottom: -88px;

        @screen lg {
            margin-bottom: -119px;
        }

		.logo-colour {
            @apply block;
        }

		.logo-white {
            @apply hidden;
        }

        .desktop-nav { 
            .contact-phone {
                @apply text-white;
            }

            .primary-list {
                > li {
                    > a { 
                        @apply text-white;

                        &:before {
                            content: "";
                            @apply absolute left-0 w-full h-0.5 bottom-0 mb-6 bg-white transition-all duration-300 opacity-0;
                        }

                        &:hover,
                        &:active, 
                        &:focus,
                        &.active {
                            &:before {
                                @apply opacity-100; 
                            }   
                        }
                    }
                }
            }
        }
    }
}