.section-three-col {

	.module {
		@apply mb-8;

		@screen lg {
			@apply w-1/3 px-3;
		}

		@screen xl {
			@apply px-5;
		}

		img {
			@apply transition-all duration-500;
		}

		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}

}

.section-three-col-copy {

	.module {
		@apply mb-8;

		p {
			@apply text-dkGrey;

			@screen lg { 
				@apply leading-relaxed;
			}
		}

		@screen lg {
			@apply w-1/3 px-2;
		}
	}

}