.section-timeline {

	.container {
		@apply relative;

		&:after {
			content: "";
			@apply absolute h-full right-0 w-12 z-20 bg-gradient-to-l top-0 from-offBlack to-transparent;
			
		}
	}

	&__list {

		&:after {
			content: "";
			@apply bg-medGrey h-1 absolute top-0 left-1/2;
			transform: translateX(-50%);
			margin-top: 42px;
			width: 72%;

			@screen lg { 
				@apply hidden;
			}
		}

		li {
			@apply w-1/5 overflow-hidden whitespace-normal inline-flex justify-center transition-all -ml-2 bg-offBlack;

			@screen lg {
				@apply w-52 inline-block;
			}
		
			> span {
				@apply z-20 flex items-center justify-center w-10 h-10 bg-offBlack border-4 border-medGrey border-solid rounded-full text-medGrey relative;

				&:before {
					content: "";
					@apply hidden bg-medGrey h-1 w-52 absolute bottom-0 left-0 mb-3 ml-8;

					@screen lg {
						@apply block;
					}
				}
			}

			&:nth-child(1) {
				opacity: 1 !important;
			}

			&.active {
				@apply opacity-100;
				> span {
					@apply bg-red border-4 border-red border-solid text-white;

					&:before {
						content: "";
						@apply bg-red;
					}
				}
			}

			@screen lg {
				@apply w-60;
			}

			@screen xl {
				@apply w-1/5;
			}
		}
	}
}