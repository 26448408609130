.section-four-col {

	.module {
		@apply mb-8;

		p {
			@apply text-dkGrey;
		}

		@screen xs {
			@apply w-1/2 px-2;
		} 

		@screen lg {
			@apply w-1/3 px-3 mb-16;
		}

		@screen xl {
			@apply w-1/4 px-5;
		}
	}

}
