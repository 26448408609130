.section-what-we-do {

	&__ul {

		@screen md {
			@apply flex items-start flex-wrap justify-start -mx-5;
		}

        li {
            @apply w-full text-lg;

            @screen md { 
            	@apply w-1/2 px-5;
            	font-size: 22px;
            }

            @screen lg { 
            	@apply w-1/3;
            }

            a {
                @apply text-white relative py-4 block;

                @screen md {  
                	@apply py-6;
                }

                &:after {
                    content: "";
                    @apply absolute left-0 w-full h-1 bottom-0 bg-dkGrey transition-all duration-300 -mb-1;

                    @screen md { 
                    	@apply h-1.5;
                    }
                }

                &:hover {
                    &:after {
                        @apply bg-white;
                    }
                }
            }
        }
	}
}