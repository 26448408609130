.section-video-swiper {

	.swiper-video {
		@apply overflow-hidden relative pb-16;

		.swiper-scrollbar {
			background: #C4C4C4;
			@apply mb-4 right-0 left-auto;

			width: calc(100% - 110px);

			&-drag {
				background: #444444;
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			@apply bottom-0 top-auto text-dkGrey transition-all duration-300;
			&:after {
				@apply hidden;
			}

			&:hover {
				@apply opacity-50;
			}
		}

		.swiper-button-next {
			@apply left-0 right-auto ml-14;
		}
	}

	lite-youtube {
		@apply max-w-none;

		.lty-playbtn {
			@apply w-16 h-16 bg-red rounded-full bg-no-repeat transition-all duration-500;
			background-image: url(/img/svg/play.svg);
			background-position: 25px center;
			background-size: 20px auto;
			filter: none;

			@screen lg {
				@apply w-40 h-40;
				background-position: 60px center;
				background-size: 50px auto;
			}

			&:hover {
				@apply bg-offBlack;
			}
		}
	}
}