// App
// Includes styles that are likely to be changed/compiled often

/* purgecss start ignore */
@import "global/header";
@import "global/footer";
@import "global/custom";
@import "partials/accordion";

@import "sections/home-banner";
@import "sections/hero-swiper";
@import "sections/what-we-do";
@import "sections/copy-over-img";
@import "sections/feat-project";
@import "sections/project-swiper";
@import "sections/img-text";
@import "sections/three-col";
@import "sections/project-summary";
@import "sections/team";
@import "sections/four-col";
@import "sections/video-swiper";
@import "sections/gallery";
@import "sections/timeline";

/* purgecss end ignore */
