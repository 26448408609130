.section-project-summary {
	&__list {
		li {
			@apply text-lg text-dkGrey py-4 border-b-2 border-solid border-ltGrey font-proxSemi;

			@screen lg { 
				@apply text-2xl;
			}
		}
	}

	&__hero-img {

		// @screen lg {
		// 	min-height: 500px;
		// }

		// @screen xl {
		// 	height: 685px;
		// }
	}
}