.section-copy-over-img {
	@screen lg {
		height: 600px;
	}

	&__copy {
		@screen lg {
			@apply top-1/2;
			transform: translateY(-50%);
		}
	}
}