.section-home-banner {
	.container {
		transform: translateX(-50%);

		@screen lg { 
			transform: translate(-50%, -50%);
		}
	}

	.hero-img {
		max-height: 550px;

		@screen lg {
			@apply max-h-full h-screen;
			min-height: 600px;
		}
	}
}