.section-feat-project {
	&__img {
		@screen lg {
			@apply right-0 max-w-none top-0;
			min-width: 1280px;
			max-height: 754px;
		}

		@screen xl {
			@apply mr-6;
			min-width: 1440px;
		}

		@screen xxl {
			min-width: 1600px;
		}

		@screen xxxl {
			min-width: 1900px;
		}
	}

	.container {
		@screen lg {
			padding-top: 23%;
		}

		@screen xxl {
			padding-top: 344px;
		}
	}

	&__copy {
		@screen lg { 
			transform: translateY(53%);
		}
	}
}