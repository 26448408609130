.section-gallery-img-height {
	height: 20rem;
	@screen md{
		height: 560px;
	}

	@screen lg{
		height: 700px;
	}

}
.section-gallery {
	.module {
		@apply relative transition-all duration-500 overflow-hidden;

		&__overlay {
			@apply bg-overlayA absolute inset-0 flex items-center justify-center; 
		}

		&:hover {
			@apply opacity-50
		}
	}

	.module-limitedheight{
		max-height: 460px;
	}

	.swiper-gallery {
		max-height: 90vh;
		.close {
			@apply cursor-pointer absolute z-10 right-0 top-0 text-offBlack bg-offWhite transition-all duration-300 w-14 h-14 flex items-center justify-center;

			@screen lg {
				@apply w-16 h-16;
			}

			&:hover {
				@apply bg-offBlack text-white;
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			@apply cursor-pointer bottom-0 top-auto text-offBlack bg-offWhite transition-all duration-300 w-14 h-14 flex items-center justify-center;

			@screen lg {
				@apply w-16 h-16;
			}

			&:after {
				@apply hidden;
			}

			&:hover {
				@apply bg-offBlack text-white;
			}
		}

		.swiper-button-prev {
			@apply left-0;
		}

		.swiper-button-next {
			@apply left-0 right-auto ml-14;
			@screen lg { 
				@apply ml-16;
			}
		}

		.swiper-button-next.swiper-button-disabled,
		.swiper-button-prev.swiper-button-disabled {
			@apply opacity-100 text-ltGrey;
		}
		.swiper-slide{
			// -webkit-transform: translate3d(0, 0, 0);
			-webkit-transform: translateZ(0);
			-webkit-backface-visibility: hidden;
		}
	}
}

.overlay-gallery {
	transition: all 0.5s;
	@apply opacity-0 invisible overflow-scroll;

	&.show {
		@apply opacity-100 visible;
		z-index: 100;
	}
}