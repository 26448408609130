// Footer

.section-footer {
	&__list {

		li {
			@apply text-xl uppercase text-white flex items-center justify-center font-proxBold;

			@screen lg { 
				@apply px-6;
			}

			a {
				@apply relative z-10;

				svg {
					@apply relative z-10;
				}

				&:before {
					content: "";
					@apply absolute w-9 h-9 rounded-full bg-red top-0 left-0 z-0 -mt-1.5 -ml-2 opacity-0 transition-all duration-300;
				}

				&:hover {
					&:before {
						@apply opacity-100;
					}
				}
			}
		}
	}

	p {
		@apply text-center text-white;

		@screen md {
			@apply text-lg;
		}
	} 
}
