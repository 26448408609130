// Accordions

.accordion {

}

.accordion__item {
	@apply relative;
}

.accordion__item:last-of-type {
	@apply mb-0;
}




.accordion__button {
	@apply absolute bottom-0 -mb-8;
	&:before {
		content: "SHOW PROFILE";
		background: url(/img/svg/plus-light.svg);
		background-size: 12px auto;
		background-position: left center;
		@apply absolute text-base text-red text-center relative font-proxBold uppercase pl-6 bg-no-repeat whitespace-nowrap;
	}

	&:after {
		content: "";
		@apply absolute bg-red w-full bottom-0 h-0.5 left-0 -mb-1;
	}

	&:hover {
		&:before {
			content: "SHOW PROFILE";
			background: url(/img/svg/plus-dark.svg);
			background-size: 12px auto;
			background-position: left center;
			@apply absolute text-base text-offBlack text-center relative font-proxBold uppercase pl-6 bg-no-repeat whitespace-nowrap;
		}

		&:after {
			content: "";
			@apply absolute bg-offBlack w-full bottom-0 h-0.5 left-0 -mb-1;
		}
	}	

	&.is-active {
		&:before { 
			content: "HIDE PROFILE";
			background: url(/img/svg/minus-light.svg);
			background-size: 12px auto;
			background-position: left center;
			@apply bg-no-repeat;
		}

		&:hover { 
			&:before { 
				content: "HIDE PROFILE";
				background: url(/img/svg/minus-dark.svg);
				background-size: 12px auto;
				background-position: left center;
				@apply bg-no-repeat text-offBlack;
			}
		}

		@screen lg {
			@apply -mb-12;
		}
	}
}


.accordion__content {
    max-height: 0;
	@apply overflow-hidden opacity-0 transition-all duration-300 ease-in-out;

	p {
		@apply text-dkGrey;
	}
}

.accordion__content p:last-child {
	@apply mb-0;
}

.accordion__content.is-active {
    max-height: 1000px;
    @apply opacity-100;
}
