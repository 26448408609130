.section-hero-swiper {
	p {
		@apply text-ltGrey;
	}

	.swiper-hero {
		@apply overflow-hidden relative pb-16;

		.swiper-scrollbar {
			background: #444444;
			@apply mb-4 right-0 left-auto;

			width: calc(100% - 110px);

			&-drag {
				background: #C4C4C4;
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			@apply bottom-0 top-auto text-white transition-all duration-300;
			&:after {
				@apply hidden;
			}

			&:hover {
				@apply bg-offBlack text-white;
			}
		}

		.swiper-button-next {
			@apply left-0 right-auto ml-14;
		}
	}
}