.section-team {

	.module {
		@apply mb-16;

		@screen lg {
			@apply w-1/2 px-4 mb-24;
		}

		@screen xl {
			@apply px-6;
		}

		ul.contact-list {
			li {
				@apply font-proxReg text-xl mb-1;

				a {
					@apply text-dkGrey;

					&:hover {
						@apply text-red;
					}
				}
			}
		}

	}

}