.section-project-swiper {

	@screen lg {
		min-height: 840px;
	}

	@screen xl {
		min-height: 920px;
	}

	&__title {
		@screen lg {
			max-width: 300px;
		}
	}

	.swiper-project {

		.swiper-slide {

			width: 300px !important;
			@apply opacity-50 transition-opacity duration-100;

			.content {
				@screen lg { 
					@apply opacity-0 transition-opacity duration-500 delay-300;
				}
			}

			@screen xs {
				width: 360px !important;
			}

			@screen sm {
				width: 425px !important;
			}

			@screen md {
				width: 520px !important;
			}

			@screen lg { 
				@apply opacity-0 duration-500;
				width: 424px !important;
			}

			&-prev {
				@screen lg { 
					@apply opacity-0;
				}
			}

			&-active {
				@apply opacity-100 transition-all;

				@screen lg { 
					width: 580px !important;

					.content {
						@apply opacity-100;
					}
				}

				@screen xl {  
					width: 705px !important;
				}
			}

			&-duplicate-next,
			&-duplicate-active {
				@screen lg { 
					@apply opacity-0 transition-none;
					width: 424px !important;
				}
			}

			&-next {
				@apply opacity-50;
			} 

		}

		.swiper-button-prev-project,
		.swiper-button-next-project {
			@apply z-20 cursor-pointer absolute bottom-auto text-offBlack bg-red transition-colors duration-300 w-14 h-14 flex items-center justify-center;
			top: 179px;

			@screen xs { 
				top: 199px;
			}

			@screen sm { 
				top: 245px;
			}

			@screen md {
				top: 313px; 
			}

			@screen lg {
				top: 347px; 
				@apply w-16 h-16;
			}

			@screen xl { 
				top: 436px; 
			}

			&:after {
				@apply hidden;
			}

			&:hover {
				@screen lg {
					@apply bg-offBlack text-white;
				}	
			}
		}

		.swiper-button-prev-project {
			@apply left-0;
		}

		.swiper-button-next-project {
			@apply left-0 right-auto ml-14;
			@screen lg { 
				@apply ml-16;
			}
		}

	}

}

.swiper-img-wrapper {
	padding-bottom: 70.9%;
	@apply relative mb-8;
}